import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    user_info:{
        'id': 0,
        'name': '',
        'surname': '',
        'mail': '',
        'phone': '',
        'licence': '',
        'password': '',
        'role': '',
        'sale': 0
    },
    user: false
  },
  getters: {
  },
  mutations: {
    authUser(state, elem){
      state.user = true;
      state.user_info = elem;
    },
    exitUser(state){
      state.user = false;
      state.user_info = {
        'id': 0,
        'name': '',
        'surname': '',
        'mail': '',
        'phone': '',
        'licence': '',
        'password': '',
        'role': '',
        'sale': 0
      };
    },
    updateUser(state, elem){
      state.user_info.name = elem.name;
      state.user_info.surname = elem.surname;
      state.user_info.mail = elem.mail;
      state.user_info.phone = elem.phone;
      state.user_info.licence = elem.licence
    },
    updatePassword(state, elem){
      state.user_info.password = elem
    },
    updateSale(state, elem){
      state.user_info.sale = elem
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState(
      {
        paths: ['user', 'user_info']
      }
    )
  ]
})
